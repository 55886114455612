// Constant imports
import { BlockedUserIds } from './constants/blocked-user-ids'
import { PrimaryColor } from './constants/colors'
import { MemeConstants } from './constants/meme-constants'
import { SupportedFonts } from './constants/supported-fonts'
import { getDefaultFontFamily } from './constants/supported-fonts'
import { SupportedImageTypes } from './constants/supported-image-types'
import { SupportedLanguages } from './constants/supported-languages'
import { WatermarkConstants } from './constants/watermark-constants'
import { SocialMediaImageDimensions } from './constants/social-media'

// Database imports
import type { BaseCaption } from './database/base-caption'
import { Caption } from './database/caption'
import type { Price } from './database/price'
import type { Product } from './database/product'
import type { Result } from './database/result'
import type { Run } from './database/run'
import type { StripeCustomer } from './database/stripe-customer'
import type { Subscription } from './database/subscription'
import type { UserDetails } from './database/user-details'
import type { UserGenCaption } from './database/user-gen-caption'
import type { User } from './database/user'

// Presentation imports
import type { BillingPeriod } from './presentation/billing-period'
import type { Dimension } from './presentation/dimension'
import type { Error } from './presentation/error'
import type { GeneratedResponse } from './presentation/generated-response'
import type { HttpError } from './presentation/http-error'
import type { MemeFormat } from './presentation/meme-format'
import type { MemeMimeType } from './presentation/meme-mime-type'
import type { MemeTemplate } from './presentation/meme-template'
import { TextToMemeGeneratedResponse } from './presentation/text-to-meme-generated-response'
import { ThemedMemeGeneratedResponse } from './presentation/themed-meme-generated-response'
import { TextToGifApiResponse } from './presentation/text-to-meme-api-response'

// Props imports
import type { EditableMemeProps } from './props/editable-meme-props'

// Interface imports
import { IMeme } from './interfaces/IMeme'
import { IPositionConfig } from './interfaces/IMeme'

// Constants exports
export {
  BlockedUserIds,
  PrimaryColor,
  MemeConstants,
  SupportedFonts,
  getDefaultFontFamily,
  SupportedImageTypes,
  SupportedLanguages,
  WatermarkConstants,
  SocialMediaImageDimensions,
}

// Database exports
export type {
  BaseCaption,
  Caption,
  Price,
  Product,
  Result,
  Run,
  StripeCustomer,
  Subscription,
  UserDetails,
  UserGenCaption,
  User,
}

// Presentation exports
export type {
  BillingPeriod,
  Dimension,
  Error,
  GeneratedResponse,
  HttpError,
  MemeFormat,
  MemeMimeType,
  MemeTemplate,
  TextToMemeGeneratedResponse,
  ThemedMemeGeneratedResponse,
  TextToGifApiResponse,
}

// Props exports
export type {
  EditableMemeProps,
}

// Interface exports
export type {
  IMeme,
  IPositionConfig,
}
